<template>
  <b-card title="Unqualified">
    <h6>You are already employed!</h6>
  </b-card>
</template>

<script>
export default {
  name: "EmployeeCompanyExist",
};
</script>

<style scoped></style>
